.animate-div {
  transition: opacity 0.5s ease;
}

.slide-up {
  animation: slideUp 0.5s forwards;
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
