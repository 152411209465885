$primary: white;
$secondary: black;

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../node_modules/bootstrap/scss/utilities";
// @import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/type";
// @import "../node_modules/bootstrap/scss/images";
// @import "../node_modules/bootstrap/scss/containers";
// @import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here


@import "../node_modules/nouislider/dist/nouislider.min.css";

@import "includes/_colors";
@import "includes/_header";
@import "includes/_viewer";

@import "_animations";
//@import "bootstrap/scss/bootstrap";
//@import "includes/_login";


$md: map-get($grid-breakpoints, "md");
$lg: map-get($grid-breakpoints, "lg"); 
$xl: map-get($grid-breakpoints, "xl");
$xxl: map-get($grid-breakpoints, "xxl");


body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  padding-top: 100px;
}

.fill {
  position: absolute;
  width: 100%;
  height: 100%;
}


#imagespaceTitle {
  padding-left: 1em;
  letter-spacing: .5rem;
}

#viewer {
  z-index: -1;
}

.passThrough {
  pointer-events: none;
}

.hidden {
  display: none;
}

.inlineImage {
  height: 2em;
}

// Override disabled button styles
.btn:disabled, .btn.disabled {
  border: none;
  // Optionally, adjust other styles like background color or opacity
  // background-color: #your-color;
  // opacity: 0.5; // Adjust opacity if needed
}

// .buttonBar {
//   height: 3em;
// }

// .buttonBar button i {
//   font-size: 2em;
//   padding: 0.5em;
// }

#levelSelectorSlider {
  width: 20px;
  height: 80vh;
}

@media (min-width: $md) {
}

@media (min-width: $lg) {
}

@media (min-width: $xl) {
}

@media (min-width: $xxl) {
}